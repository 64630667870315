





























import Vue from "vue";
import tabs from "@/components/tabs.vue";
import dropMenu from "@/components/dropMenu.vue";
import theme from "@/components/theme.vue";
export default Vue.extend({
  components: {
    tabs,
    dropMenu,
    theme,
  },
  mounted() {
    const theme = JSON.parse(
      localStorage.getItem("lucas-portifolio") || "false"
    );
    if (
      theme?.theme === "dark" ||
      this.$store.state.theme === "dark" ||
      (!theme && window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      this.$store.dispatch("CHANGE_THEME", {
        theme: "dark",
      });
    } else {
      this.$store.dispatch("CHANGE_THEME", {
        theme: "light",
      });
    }
  },
  data: () => ({
    modal: false,
  }),
  methods: {
    closeModal() {
      this.modal = false;
    },
  },
});
