








































































import Vue from "vue";
import card from "./card.vue";
import { projects } from "@/mixins/constraints";
export default Vue.extend({
  components: {
    card,
  },
  data: () => ({
    screen: "front",
  }),
  computed: {
    projects() {
      return projects.filter((el) => el.type === this.screen);
    },
  },
});
