
























import Vue from "vue";
import type { PropType } from "vue";

interface ISkill {
  name: string;
  icon: string;
  warn?: boolean;
}
export default Vue.extend({
  props: {
    skill: {
      required: true,
      type: Object as PropType<ISkill>,
    },
  },
});
