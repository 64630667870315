


















import Vue, { PropType } from "vue";
import card from "./card.vue";
import { skills } from "@/mixins/constraints";
export default Vue.extend({
  props: {
    type: {
      type: String as PropType<"front" | "back" | "mobile">,
      required: true,
    },
  },
  data() {
    return { skills };
  },

  components: { card },
  computed: {
    title(): string {
      const title = {
        front: "Front-end",
        back: "Back-end",
        mobile: "Mobile",
      };
      return title[this.type] || "";
    },
  },
});
