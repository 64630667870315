
































import Vue from "vue";
export default Vue.extend({
  methods: {
    changeTheme() {
      this.$store.dispatch("CHANGE_THEME", {
        theme: this.isDark ? "light" : "dark",
      });
    },
  },
  computed: {
    isDark() {
      return this.$store.state.theme === "dark";
    },
  },
});
