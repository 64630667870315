















import Vue from "vue";
interface ITab {
  name: string;
  path: string;
}
export default Vue.extend({
  data: () => ({
    tabs: [
      { name: "INICIO", path: "/" },
      { name: "HABILIDADES", path: "/skill" },
      { name: "PROJETOS", path: "/project" },
      { name: "SOBRE MIM", path: "/about" },
    ] as ITab[],
  }),
  computed: {
    pathAtual() {
      return this.$route.path;
    },
  },
});
