






















































import Vue from "vue";
import type { PropType } from "vue";

interface IProject {
  name: string;
  describe: string;
  img: string;
  link: string;
  git: string;
  type: string;
}
export default Vue.extend({
  props: {
    project: {
      required: true,
      type: Object as PropType<IProject>,
    },
  },
  methods: {
    imgUrlAlt(event: any) {
      event.target.src = require("@/assets/icons/LN.svg");
    },
  },
});
