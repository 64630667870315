









import Vue from "vue";
import list from "./list.vue";
export default Vue.extend({
  components: {
    list,
  },
});
